import {AppRoutes, getRouteMain, getRouteSupport, getRouteTermsOfUse} from "src/shared/config/router";
import {AppRoutesProps} from "src/shared/types/router";
import {MainPage} from "src/pages/mainPage/mainPage";
import {TermsOfUse} from "src/pages/termsOfUse/termsOfUse";
import {Support} from "src/pages/support/support";

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.MAIN]: {
    path: getRouteMain(),
    element: <MainPage />,
  },
  [AppRoutes.TERMSOFUSE]: {
    path: getRouteTermsOfUse(),
    element: <TermsOfUse />,
  },
  [AppRoutes.SUPPORT]: {
    path: getRouteSupport(),
    element: <Support />,
  },
};
