import { Suspense, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routeConfig } from 'src/shared/config/routeConfig/routeConfig';
import { type FC } from 'react';
import { AppRoutesProps } from 'src/shared/types/router';

export const AppRouter: FC = () => {
  const renderWithWrapper = useCallback((route: AppRoutesProps) => {

    return (
      <Route
        key={route.path}
        path={route.path}
        element={route.element}
      />
    );
  }, []);

  return (
    <>
      <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>
    </>
  );
};

