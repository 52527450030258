export enum AppRoutes {
  MAIN = 'main',
  TERMSOFUSE = 'terms-of-use',
  SUPPORT = 'support'
}

export const getRouteMain = () => '/';
export const getRouteTermsOfUse = () => '/terms-of-use';
export const getRouteSupport = () => '/support';


export const AppRouteByPathPattern: Record<string, AppRoutes> = {
  [getRouteMain()]: AppRoutes.MAIN,
  [getRouteTermsOfUse()]: AppRoutes.TERMSOFUSE,
  [getRouteSupport()]: AppRoutes.SUPPORT
};
