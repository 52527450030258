import React from 'react';
import {classNames} from "src/shared/lib/classNames/classNames";
import cls from "./support.module.scss";
import {Helmet} from "react-helmet";
import {AppText} from "src/shared/ui/AppText";
import {Link} from "react-router-dom";
import {AppTextTheme} from "src/shared/ui/AppText/ui/AppText";
import Logo from "./img/logo-link.png";

export const Support = () => {
    return (
        <section className={classNames(cls.supportPage, {}, [])}>
            <Helmet>
                <title>{'ROD Walk support'}</title>
                <meta name="description" content={''} />
                <meta name="keywords" content={''} />
            </Helmet>
            <Link to={'/'}>
                <img src={Logo} alt="Logo"/>
            </Link>
            <AppText
                className={cls.supportPageTitle}
                text={'Do you have anything to ask or share? Please, send your questions, suggestions and proposals to our e-mail:'}
                fontSize={40}
                fontWeight={700}
                lineHeight={46}
                align={"center"}
            />
            <AppText
                className={cls.supportPageEmail}
                text={'support@rodwalk.xyz'}
                fontSize={24}
                fontWeight={400}
                lineHeight={26}
                theme={AppTextTheme.BLUE}
                />
        </section>
    );
};

